import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        clientsList: build.query<ClientsListApiResponse, ClientsListApiArg>({
            query: queryArg => ({
                url: `/v2/clients/`,
                params: {
                    companyuser__name: queryArg.companyuserName,
                    companyuser__organization: queryArg.companyuserOrganization,
                    companyuser__surname: queryArg.companyuserSurname,
                    date_joined: queryArg.dateJoined,
                    email: queryArg.email,
                    has_roles: queryArg.hasRoles,
                    id__in: queryArg.idIn,
                    last_time_uploaded_from: queryArg.lastTimeUploadedFrom,
                    last_time_uploaded_to: queryArg.lastTimeUploadedTo,
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    ordering: queryArg.ordering,
                    search: queryArg.search,
                    with_organization: queryArg.withOrganization,
                },
            }),
        }),
        clientsCreate: build.mutation<ClientsCreateApiResponse, ClientsCreateApiArg>({
            query: queryArg => ({ url: `/v2/clients/`, method: 'POST', body: queryArg.createUserRequest }),
        }),
        clientsRetrieve: build.query<ClientsRetrieveApiResponse, ClientsRetrieveApiArg>({
            query: queryArg => ({ url: `/v2/clients/${queryArg.id}/` }),
        }),
        clientsMeRetrieve: build.query<ClientsMeRetrieveApiResponse, ClientsMeRetrieveApiArg>({
            query: () => ({ url: `/v2/clients/me/` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ClientsListApiResponse = /** status 200  */ PaginatedClientExtendedRepresentationList;
export type ClientsListApiArg = {
    companyuserName?: string;
    companyuserOrganization?: number;
    companyuserSurname?: string;
    dateJoined?: string;
    email?: string;
    hasRoles?: boolean;
    /** Multiple values may be separated by commas. */
    idIn?: number[];
    lastTimeUploadedFrom?: string;
    lastTimeUploadedTo?: string;
    /** Number of results to return per page. */
    limit?: number;
    /** The initial index from which to return the results. */
    offset?: number;
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A search term. */
    search?: string;
    withOrganization?: boolean;
};
export type ClientsCreateApiResponse = /** status 201  */ ClientExtendedRepresentation;
export type ClientsCreateApiArg = {
    createUserRequest: CreateUserRequest;
};
export type ClientsRetrieveApiResponse = /** status 200  */ ClientExtendedRepresentationDetailed;
export type ClientsRetrieveApiArg = {
    id: number;
};
export type ClientsMeRetrieveApiResponse = /** status 200  */ ClientExtendedRepresentationSelf;
export type ClientsMeRetrieveApiArg = void;
export type ShortOrganization = {
    id: number;
    title: string;
    domain: string;
};
export type ClientExtendedRepresentation = {
    id: number;
    email: string;
    created_at: string;
    phone_number?: string;
    name?: string;
    surname?: string;
    full_name: string;
    company_name?: string;
    position?: string;
    roles?: string[];
    /** Percentage of the expected down payment derived from the total, if applicable to the end-user. */
    down_payment_rate?: number;
    orders_count: number;
    models_count: number;
    last_time_uploaded: string;
    organization: ShortOrganization;
    self_notes?: string;
};
export type PaginatedClientExtendedRepresentationList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: ClientExtendedRepresentation[];
};
export type CreateUserRequest = {
    email: string;
    password?: string;
    name?: string;
    surname?: string;
    phone?: string;
    send_notification?: boolean;
    notification_redirect_path?: string;
    silent_login_enabled?: boolean;
    roles?: (string | null)[];
    organization_id?: number | null;
};
export type CountryEnum =
    | 'AF'
    | 'AL'
    | 'DZ'
    | 'AD'
    | 'AR'
    | 'AO'
    | 'AI'
    | 'AQ'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BM'
    | 'BT'
    | 'BO'
    | 'BA'
    | 'BW'
    | 'BR'
    | 'BG'
    | 'BL'
    | 'BH'
    | 'CV'
    | 'KH'
    | 'KW'
    | 'CA'
    | 'CL'
    | 'CN'
    | 'CO'
    | 'CD'
    | 'CR'
    | 'HR'
    | 'CU'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DO'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'EE'
    | 'ET'
    | 'FI'
    | 'FR'
    | 'GE'
    | 'DE'
    | 'GI'
    | 'GR'
    | 'GL'
    | 'GP'
    | 'GT'
    | 'HT'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IL'
    | 'IT'
    | 'JM'
    | 'JO'
    | 'JP'
    | 'KZ'
    | 'KE'
    | 'KR'
    | 'KG'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MO'
    | 'MK'
    | 'MY'
    | 'MV'
    | 'MT'
    | 'MX'
    | 'MD'
    | 'MC'
    | 'ME'
    | 'MA'
    | 'NP'
    | 'NL'
    | 'NC'
    | 'NZ'
    | 'NI'
    | 'NG'
    | 'NF'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PA'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PL'
    | 'PT'
    | 'PR'
    | 'QA'
    | 'RO'
    | 'RU'
    | 'WS'
    | 'SM'
    | 'SA'
    | 'RS'
    | 'SG'
    | 'SK'
    | 'SI'
    | 'SO'
    | 'ZA'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SE'
    | 'CH'
    | 'TW'
    | 'TJ'
    | 'TH'
    | 'TO'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'YE'
    | 'ZW';
export type NullEnum = null;
export type StateEnum =
    | 'AL'
    | 'AK'
    | 'AZ'
    | 'AR'
    | 'CA'
    | 'CO'
    | 'CT'
    | 'DE'
    | 'DC'
    | 'FL'
    | 'GA'
    | 'GU'
    | 'HI'
    | 'ID'
    | 'IL'
    | 'IN'
    | 'IA'
    | 'KS'
    | 'KY'
    | 'LA'
    | 'ME'
    | 'MD'
    | 'MA'
    | 'MI'
    | 'MN'
    | 'MS'
    | 'MO'
    | 'MT'
    | 'NE'
    | 'NV'
    | 'NH'
    | 'NJ'
    | 'NM'
    | 'NY'
    | 'NC'
    | 'ND'
    | 'OH'
    | 'OK'
    | 'OR'
    | 'PW'
    | 'PA'
    | 'PR'
    | 'RI'
    | 'SC'
    | 'SD'
    | 'TN'
    | 'TX'
    | 'UT'
    | 'VT'
    | 'VI'
    | 'VA'
    | 'WA'
    | 'WV'
    | 'WI'
    | 'WY'
    | 'AB'
    | 'BC'
    | 'MB'
    | 'NB'
    | 'NL'
    | 'NS'
    | 'NT'
    | 'NU'
    | 'ON'
    | 'PE'
    | 'QC'
    | 'SK'
    | 'YT';
export type BlankEnum = '';
export type AddressTypeEnum = 'delivery' | 'customer_company';
export type CompanyUserAddress = {
    id: number;
    full_address: string;
    country: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city: string;
    street_address: string;
    apartment?: string;
    postcode?: string;
    company_name?: string;
    vat_number?: string;
    address_type: AddressTypeEnum;
    created_at: string;
    updated_at: string;
};
export type OrderAddress = {
    full_address: string;
    country: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city: string;
    street_address: string;
    apartment?: string;
    postcode?: string;
    company_name?: string;
    vat_number?: string;
    address_type: AddressTypeEnum;
};
export type ClientExtendedRepresentationDetailed = {
    id: number;
    email: string;
    created_at: string;
    phone_number?: string;
    name?: string;
    surname?: string;
    full_name: string;
    company_name?: string;
    position?: string;
    roles?: string[];
    /** Percentage of the expected down payment derived from the total, if applicable to the end-user. */
    down_payment_rate?: number;
    billing_addresses?: CompanyUserAddress[];
    delivery_addresses?: CompanyUserAddress[];
    last_billing_address?: OrderAddress;
    last_delivery_address?: OrderAddress;
    last_vat_number?: string;
    orders_count: number;
    models_count: number;
    last_time_uploaded: string;
    organization: ShortOrganization;
    self_notes?: string;
};
export type ClientExtendedRepresentationSelf = {
    id: number;
    email: string;
    created_at: string;
    phone_number?: string;
    name?: string;
    surname?: string;
    full_name: string;
    company_name?: string;
    position?: string;
    roles?: string[];
    /** Percentage of the expected down payment derived from the total, if applicable to the end-user. */
    down_payment_rate?: number;
    billing_addresses?: CompanyUserAddress[];
    delivery_addresses?: CompanyUserAddress[];
    last_billing_address?: OrderAddress;
    last_delivery_address?: OrderAddress;
    last_vat_number?: string;
    orders_count: number;
    models_count: number;
    last_time_uploaded: string;
    organization: ShortOrganization;
};
export const { useClientsListQuery, useClientsCreateMutation, useClientsRetrieveQuery, useClientsMeRetrieveQuery } =
    injectedRtkApi;
