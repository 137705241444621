export enum AcceptDrawing {
    NotAccept = 'not_accept',
    AcceptRequire = 'accept_require',
    AcceptNotRequire = 'accept_not_require'
}
export enum AddressType {
    Delivery = 'delivery',
    CustomerCompany = 'customer_company'
}
export enum AutoQuoteOrderDetailsStatus {
    WaitingForReview = 'waiting_for_review',
    Processed = 'processed'
}
export enum Axis {
    X = 'x',
    Y = 'y',
    Z = 'z'
}
export enum BatchRecurringMode {
    ChamberVolume = 'chamber_volume',
    PlateArea = 'plate_area'
}
export enum CompanyLanguageCode {
    En = 'en',
    EnAu = 'en-au',
    EnGb = 'en-gb',
    Ar = 'ar',
    De = 'de',
    Fr = 'fr',
    Nl = 'nl',
    Ru = 'ru',
    Cs = 'cs',
    It = 'it',
    Es = 'es',
    Pl = 'pl',
    Th = 'th',
    ZhHans = 'zh-hans',
    ZhHk = 'zh-hk',
    ZhTw = 'zh-tw',
    Ko = 'ko',
    Hu = 'hu',
    Hr = 'hr',
    Sl = 'sl',
    Sv = 'sv',
    Uk = 'uk',
    Da = 'da',
    Tr = 'tr',
    No = 'no',
    Sr = 'sr',
    He = 'he'
}
export enum CompanySendersVerifiedIdentityStatus {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
    Temporaryfailure = 'temporaryfailure'
}
export enum CompanySendersVerifiedIdentityType {
    Email = 'email',
    Domain = 'domain'
}
export enum Country {
    Af = 'AF',
    Al = 'AL',
    Dz = 'DZ',
    Ad = 'AD',
    Ar = 'AR',
    Ao = 'AO',
    Ai = 'AI',
    Aq = 'AQ',
    Am = 'AM',
    Aw = 'AW',
    Au = 'AU',
    At = 'AT',
    Az = 'AZ',
    Bd = 'BD',
    Bb = 'BB',
    By = 'BY',
    Be = 'BE',
    Bz = 'BZ',
    Bj = 'BJ',
    Bm = 'BM',
    Bt = 'BT',
    Bo = 'BO',
    Ba = 'BA',
    Bw = 'BW',
    Br = 'BR',
    Bg = 'BG',
    Bl = 'BL',
    Bh = 'BH',
    Cv = 'CV',
    Kh = 'KH',
    Kw = 'KW',
    Ca = 'CA',
    Cl = 'CL',
    Cn = 'CN',
    Co = 'CO',
    Cd = 'CD',
    Cr = 'CR',
    Hr = 'HR',
    Cu = 'CU',
    Cy = 'CY',
    Cz = 'CZ',
    Dk = 'DK',
    Do = 'DO',
    Ec = 'EC',
    Eg = 'EG',
    Sv = 'SV',
    Ee = 'EE',
    Et = 'ET',
    Fi = 'FI',
    Fr = 'FR',
    Ge = 'GE',
    De = 'DE',
    Gi = 'GI',
    Gr = 'GR',
    Gl = 'GL',
    Gp = 'GP',
    Gt = 'GT',
    Ht = 'HT',
    Hn = 'HN',
    Hk = 'HK',
    Hu = 'HU',
    Is = 'IS',
    In = 'IN',
    Id = 'ID',
    Ir = 'IR',
    Iq = 'IQ',
    Ie = 'IE',
    Il = 'IL',
    It = 'IT',
    Jm = 'JM',
    Jo = 'JO',
    Jp = 'JP',
    Kz = 'KZ',
    Ke = 'KE',
    Kr = 'KR',
    Kg = 'KG',
    Lv = 'LV',
    Lb = 'LB',
    Ls = 'LS',
    Lr = 'LR',
    Ly = 'LY',
    Li = 'LI',
    Lt = 'LT',
    Lu = 'LU',
    Mo = 'MO',
    Mk = 'MK',
    My = 'MY',
    Mv = 'MV',
    Mt = 'MT',
    Mx = 'MX',
    Md = 'MD',
    Mc = 'MC',
    Me = 'ME',
    Ma = 'MA',
    Np = 'NP',
    Nl = 'NL',
    Nc = 'NC',
    Nz = 'NZ',
    Ni = 'NI',
    Ng = 'NG',
    Nf = 'NF',
    No = 'NO',
    Om = 'OM',
    Pk = 'PK',
    Pa = 'PA',
    Py = 'PY',
    Pe = 'PE',
    Ph = 'PH',
    Pl = 'PL',
    Pt = 'PT',
    Pr = 'PR',
    Qa = 'QA',
    Ro = 'RO',
    Ru = 'RU',
    Ws = 'WS',
    Sm = 'SM',
    Sa = 'SA',
    Rs = 'RS',
    Sg = 'SG',
    Sk = 'SK',
    Si = 'SI',
    So = 'SO',
    Za = 'ZA',
    Es = 'ES',
    Lk = 'LK',
    Sd = 'SD',
    Se = 'SE',
    Ch = 'CH',
    Tw = 'TW',
    Tj = 'TJ',
    Th = 'TH',
    To = 'TO',
    Tn = 'TN',
    Tr = 'TR',
    Tm = 'TM',
    Ug = 'UG',
    Ua = 'UA',
    Ae = 'AE',
    Gb = 'GB',
    Us = 'US',
    Uy = 'UY',
    Uz = 'UZ',
    Vu = 'VU',
    Ve = 'VE',
    Vn = 'VN',
    Ye = 'YE',
    Zw = 'ZW'
}
export enum CurrencyCode {
    Aed = 'aed',
    Aud = 'aud',
    Brl = 'brl',
    Cad = 'cad',
    Chf = 'chf',
    Clp = 'clp',
    Czk = 'czk',
    Dkk = 'dkk',
    Eur = 'eur',
    Gbp = 'gbp',
    Hkd = 'hkd',
    Huf = 'huf',
    Idr = 'idr',
    Ils = 'ils',
    Inr = 'inr',
    Krw = 'krw',
    Mxn = 'mxn',
    Myr = 'myr',
    Ngn = 'ngn',
    Nok = 'nok',
    Nzd = 'nzd',
    Pln = 'pln',
    Rsd = 'rsd',
    Rub = 'rub',
    Sar = 'sar',
    Sek = 'sek',
    Sgd = 'sgd',
    Thb = 'thb',
    Try = 'try',
    Twd = 'twd',
    Uah = 'uah',
    Usd = 'usd',
    Zar = 'zar'
}
export enum CurrencyDecimalDigits {
    CharCode48 = 0,
    CharCode50 = 2
}
export enum CurrencyFormatDecimalSeparator {
    CharCode46 = '.',
    CharCode44 = ','
}
export enum CurrencyFormatSignPosition {
    Left = 'left',
    Right = 'right'
}
export enum CurrencyFormatThousandSeparator {
    CharCode46 = '.',
    CharCode44 = ',',
    CharCode160 = '\u00A0'
}
export enum DiscountType {
    Promo = 'promo',
    Client = 'client',
    Order = 'order',
    Batch = 'batch',
    Hourly = 'hourly',
    SqCmOfMaterial = 'sq_cm_of_material'
}
export enum DocumentType {
    Invoices = 'invoices',
    Proforma = 'proforma'
}
export enum ExecutionType {
    LayerThickness = 'layer_thickness',
    Filling = 'filling',
    Color = 'color'
}
export enum FeatureType {
    Cuboids = 'cuboids',
    Axial = 'axial',
    Radial = 'radial',
    SingleStepReduction = 'single_step_reduction'
}
export enum FileType {
    Internal = 'internal',
    Client = 'client',
    SheetNesting = 'sheet_nesting'
}
export enum FormType {
    PostProductionDefault = 'post_production_default',
    PostProductionColoring = 'post_production_coloring'
}
export enum GeometryType {
    Shaft = 'shaft',
    Disk = 'disk',
    Sheet = 'sheet',
    Sheetmetal = 'sheetmetal',
    FlatSheet = 'flat_sheet',
    Other = 'other'
}
export enum InitialStatus {
    InProgress = 'in_progress',
    Ready = 'ready',
    Failed = 'failed'
}
export enum LoggingLevel {
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}
export enum MaterialSelectionMode {
    Preselection = 'preselection',
    RepeatSpecs = 'repeat_specs',
    FullPreselection = 'full_preselection'
}
export enum ModelUnits {
    Mm = 'mm',
    Cm = 'cm',
    In = 'in'
}
export enum ModelsType {
    Original = 'original',
    Repaired = 'repaired'
}
export enum OrderCreateStatus {
    Created = 'created',
    WaitingForReview = 'waiting_for_review',
    Placed = 'placed',
    FirmOfferSent = 'firm_offer_sent',
    Initial = 'initial'
}
export enum OrderPriceCorrectionType {
    General = 'general',
    Delivery = 'delivery'
}
export enum OrderStatus {
    Created = 'created',
    Initial = 'initial',
    WaitingForReview = 'waiting_for_review',
    FirmOfferSent = 'firm_offer_sent',
    Placed = 'placed',
    PoProvided = 'po_provided',
    WaitingForPayment = 'waiting_for_payment',
    OnHold = 'on_hold',
    FreeForManufacturing = 'free_for_manufacturing',
    PlanningConfirmed = 'planning_confirmed',
    Manufacturing = 'manufacturing',
    PostProduction = 'post_production',
    ReManufacturing = 're_manufacturing',
    ReadyForShipping = 'ready_for_shipping',
    Shipped = 'shipped',
    ReadyForPickup = 'ready_for_pickup',
    Delivered = 'delivered',
    PoPaymentDue = 'po_payment_due',
    CancelledByUser = 'cancelled_by_user',
    CancelledByAdmin = 'cancelled_by_admin'
}
export enum OrdersReviewType {
    InstantPayment = 'instant_payment',
    ManualReview = 'manual_review',
    PaymentOrManualReview = 'payment_or_manual_review'
}
export enum PaymentMethod {
    Paypal = 'paypal',
    Stripe = 'stripe',
    Payfast = 'payfast',
    Converge = 'converge',
    WiresOnly = 'wires-only'
}
export enum PaymentStatus {
    PaymentStageDenied = 'PaymentStage.denied',
    PaymentStageNeedsInitialization = 'PaymentStage.needs_initialization',
    PaymentStageAllowed = 'PaymentStage.allowed',
    PaymentStageProcessing = 'PaymentStage.processing',
    PaymentStagePaid = 'PaymentStage.paid',
    PaymentStageCancelled = 'PaymentStage.cancelled'
}
export enum PollUsersChoice {
    PriceTooHigh = 'price_too_high',
    TimeTooLong = 'time_too_long',
    NoMaterial = 'no_material',
    ExtraTime = 'extra_time',
    Other = 'other'
}
export enum PriceUnits {
    PerModel = 'per_model',
    PerCm2 = 'per_cm_2',
    PerCm3 = 'per_cm_3',
    PerBb = 'per_bb'
}
export enum PricingMethod {
    BarStocks = 'bar_stocks',
    RoundBarStocks = 'round_bar_stocks',
    PerGram = 'per_gram'
}
export enum ProcessingStatus {
    InProgress = 'in_progress',
    Success = 'success',
    Failed = 'failed'
}
export enum Service {
    Stripe = 'stripe',
    Paypal = 'paypal',
    Easypost = 'easypost',
    Hubspot = 'hubspot',
    QuickbooksOnline = 'quickbooks_online',
    Salesforce = 'salesforce',
    Sharepoint = 'sharepoint',
    Trello = 'trello',
    Xero = 'xero',
    Zoho = 'zoho',
    Webhooks = 'webhooks'
}
export enum ShapeType {
    Simple = 'simple',
    Countersunk = 'countersunk',
    Counterdrilled = 'counterdrilled',
    Counterbored = 'counterbored'
}
export enum ShippingType {
    International = 'international',
    Domestic = 'domestic',
    City = 'city',
    PickUp = 'pick-up'
}
export enum Source {
    Email = 'email',
    Auto = 'auto'
}
export enum SpecStringTemplate {
    LayerThickness = 'layer_thickness',
    Filling = 'filling',
    Color = 'color',
    PostProduction = 'post_production',
    LeadTime = 'lead_time',
    Tolerance = 'tolerance',
    Thickness = 'thickness',
    Features = 'features',
    DfmFeatures = 'dfm_features',
    ExtraFieldsets = 'extra_fieldsets',
    Technology = 'technology'
}
export enum State {
    Al = 'AL',
    Ak = 'AK',
    Az = 'AZ',
    Ar = 'AR',
    Ca = 'CA',
    Co = 'CO',
    Ct = 'CT',
    De = 'DE',
    Dc = 'DC',
    Fl = 'FL',
    Ga = 'GA',
    Gu = 'GU',
    Hi = 'HI',
    Id = 'ID',
    Il = 'IL',
    In = 'IN',
    Ia = 'IA',
    Ks = 'KS',
    Ky = 'KY',
    La = 'LA',
    Me = 'ME',
    Md = 'MD',
    Ma = 'MA',
    Mi = 'MI',
    Mn = 'MN',
    Ms = 'MS',
    Mo = 'MO',
    Mt = 'MT',
    Ne = 'NE',
    Nv = 'NV',
    Nh = 'NH',
    Nj = 'NJ',
    Nm = 'NM',
    Ny = 'NY',
    Nc = 'NC',
    Nd = 'ND',
    Oh = 'OH',
    Ok = 'OK',
    Or = 'OR',
    Pw = 'PW',
    Pa = 'PA',
    Pr = 'PR',
    Ri = 'RI',
    Sc = 'SC',
    Sd = 'SD',
    Tn = 'TN',
    Tx = 'TX',
    Ut = 'UT',
    Vt = 'VT',
    Vi = 'VI',
    Va = 'VA',
    Wa = 'WA',
    Wv = 'WV',
    Wi = 'WI',
    Wy = 'WY',
    Ab = 'AB',
    Bc = 'BC',
    Mb = 'MB',
    Nb = 'NB',
    Nl = 'NL',
    Ns = 'NS',
    Nt = 'NT',
    Nu = 'NU',
    On = 'ON',
    Pe = 'PE',
    Qc = 'QC',
    Sk = 'SK',
    Yt = 'YT'
}
export enum TbdReason {
    LineItemTotal = 'line_item_total',
    OrderTotal = 'order_total',
    MachineQuantity = 'machine_quantity',
    NonCad = 'non_cad',
    LeadTimeQuantity = 'lead_time_quantity'
}
export enum Through {
    Through = 'through',
    NotThrough = 'not_through'
}
export enum Timezone {
    AfricaAbidjan = 'Africa/Abidjan',
    AfricaAccra = 'Africa/Accra',
    AfricaAddisAbaba = 'Africa/Addis_Ababa',
    AfricaAlgiers = 'Africa/Algiers',
    AfricaAsmara = 'Africa/Asmara',
    AfricaBamako = 'Africa/Bamako',
    AfricaBangui = 'Africa/Bangui',
    AfricaBanjul = 'Africa/Banjul',
    AfricaBissau = 'Africa/Bissau',
    AfricaBlantyre = 'Africa/Blantyre',
    AfricaBrazzaville = 'Africa/Brazzaville',
    AfricaBujumbura = 'Africa/Bujumbura',
    AfricaCairo = 'Africa/Cairo',
    AfricaCasablanca = 'Africa/Casablanca',
    AfricaCeuta = 'Africa/Ceuta',
    AfricaConakry = 'Africa/Conakry',
    AfricaDakar = 'Africa/Dakar',
    AfricaDarEsSalaam = 'Africa/Dar_es_Salaam',
    AfricaDjibouti = 'Africa/Djibouti',
    AfricaDouala = 'Africa/Douala',
    AfricaElAaiun = 'Africa/El_Aaiun',
    AfricaFreetown = 'Africa/Freetown',
    AfricaGaborone = 'Africa/Gaborone',
    AfricaHarare = 'Africa/Harare',
    AfricaJohannesburg = 'Africa/Johannesburg',
    AfricaJuba = 'Africa/Juba',
    AfricaKampala = 'Africa/Kampala',
    AfricaKhartoum = 'Africa/Khartoum',
    AfricaKigali = 'Africa/Kigali',
    AfricaKinshasa = 'Africa/Kinshasa',
    AfricaLagos = 'Africa/Lagos',
    AfricaLibreville = 'Africa/Libreville',
    AfricaLome = 'Africa/Lome',
    AfricaLuanda = 'Africa/Luanda',
    AfricaLubumbashi = 'Africa/Lubumbashi',
    AfricaLusaka = 'Africa/Lusaka',
    AfricaMalabo = 'Africa/Malabo',
    AfricaMaputo = 'Africa/Maputo',
    AfricaMaseru = 'Africa/Maseru',
    AfricaMbabane = 'Africa/Mbabane',
    AfricaMogadishu = 'Africa/Mogadishu',
    AfricaMonrovia = 'Africa/Monrovia',
    AfricaNairobi = 'Africa/Nairobi',
    AfricaNdjamena = 'Africa/Ndjamena',
    AfricaNiamey = 'Africa/Niamey',
    AfricaNouakchott = 'Africa/Nouakchott',
    AfricaOuagadougou = 'Africa/Ouagadougou',
    AfricaPortoNovo = 'Africa/Porto-Novo',
    AfricaSaoTome = 'Africa/Sao_Tome',
    AfricaTripoli = 'Africa/Tripoli',
    AfricaTunis = 'Africa/Tunis',
    AfricaWindhoek = 'Africa/Windhoek',
    AmericaAdak = 'America/Adak',
    AmericaAnchorage = 'America/Anchorage',
    AmericaAnguilla = 'America/Anguilla',
    AmericaAntigua = 'America/Antigua',
    AmericaAraguaina = 'America/Araguaina',
    AmericaArgentinaBuenosAires = 'America/Argentina/Buenos_Aires',
    AmericaArgentinaCatamarca = 'America/Argentina/Catamarca',
    AmericaArgentinaCordoba = 'America/Argentina/Cordoba',
    AmericaArgentinaJujuy = 'America/Argentina/Jujuy',
    AmericaArgentinaLaRioja = 'America/Argentina/La_Rioja',
    AmericaArgentinaMendoza = 'America/Argentina/Mendoza',
    AmericaArgentinaRioGallegos = 'America/Argentina/Rio_Gallegos',
    AmericaArgentinaSalta = 'America/Argentina/Salta',
    AmericaArgentinaSanJuan = 'America/Argentina/San_Juan',
    AmericaArgentinaSanLuis = 'America/Argentina/San_Luis',
    AmericaArgentinaTucuman = 'America/Argentina/Tucuman',
    AmericaArgentinaUshuaia = 'America/Argentina/Ushuaia',
    AmericaAruba = 'America/Aruba',
    AmericaAsuncion = 'America/Asuncion',
    AmericaAtikokan = 'America/Atikokan',
    AmericaBahia = 'America/Bahia',
    AmericaBahiaBanderas = 'America/Bahia_Banderas',
    AmericaBarbados = 'America/Barbados',
    AmericaBelem = 'America/Belem',
    AmericaBelize = 'America/Belize',
    AmericaBlancSablon = 'America/Blanc-Sablon',
    AmericaBoaVista = 'America/Boa_Vista',
    AmericaBogota = 'America/Bogota',
    AmericaBoise = 'America/Boise',
    AmericaCambridgeBay = 'America/Cambridge_Bay',
    AmericaCampoGrande = 'America/Campo_Grande',
    AmericaCancun = 'America/Cancun',
    AmericaCaracas = 'America/Caracas',
    AmericaCayenne = 'America/Cayenne',
    AmericaCayman = 'America/Cayman',
    AmericaChicago = 'America/Chicago',
    AmericaChihuahua = 'America/Chihuahua',
    AmericaCiudadJuarez = 'America/Ciudad_Juarez',
    AmericaCostaRica = 'America/Costa_Rica',
    AmericaCreston = 'America/Creston',
    AmericaCuiaba = 'America/Cuiaba',
    AmericaCuracao = 'America/Curacao',
    AmericaDanmarkshavn = 'America/Danmarkshavn',
    AmericaDawson = 'America/Dawson',
    AmericaDawsonCreek = 'America/Dawson_Creek',
    AmericaDenver = 'America/Denver',
    AmericaDetroit = 'America/Detroit',
    AmericaDominica = 'America/Dominica',
    AmericaEdmonton = 'America/Edmonton',
    AmericaEirunepe = 'America/Eirunepe',
    AmericaElSalvador = 'America/El_Salvador',
    AmericaFortNelson = 'America/Fort_Nelson',
    AmericaFortaleza = 'America/Fortaleza',
    AmericaGlaceBay = 'America/Glace_Bay',
    AmericaGooseBay = 'America/Goose_Bay',
    AmericaGrandTurk = 'America/Grand_Turk',
    AmericaGrenada = 'America/Grenada',
    AmericaGuadeloupe = 'America/Guadeloupe',
    AmericaGuatemala = 'America/Guatemala',
    AmericaGuayaquil = 'America/Guayaquil',
    AmericaGuyana = 'America/Guyana',
    AmericaHalifax = 'America/Halifax',
    AmericaHavana = 'America/Havana',
    AmericaHermosillo = 'America/Hermosillo',
    AmericaIndianaIndianapolis = 'America/Indiana/Indianapolis',
    AmericaIndianaKnox = 'America/Indiana/Knox',
    AmericaIndianaMarengo = 'America/Indiana/Marengo',
    AmericaIndianaPetersburg = 'America/Indiana/Petersburg',
    AmericaIndianaTellCity = 'America/Indiana/Tell_City',
    AmericaIndianaVevay = 'America/Indiana/Vevay',
    AmericaIndianaVincennes = 'America/Indiana/Vincennes',
    AmericaIndianaWinamac = 'America/Indiana/Winamac',
    AmericaInuvik = 'America/Inuvik',
    AmericaIqaluit = 'America/Iqaluit',
    AmericaJamaica = 'America/Jamaica',
    AmericaJuneau = 'America/Juneau',
    AmericaKentuckyLouisville = 'America/Kentucky/Louisville',
    AmericaKentuckyMonticello = 'America/Kentucky/Monticello',
    AmericaKralendijk = 'America/Kralendijk',
    AmericaLaPaz = 'America/La_Paz',
    AmericaLima = 'America/Lima',
    AmericaLosAngeles = 'America/Los_Angeles',
    AmericaLowerPrinces = 'America/Lower_Princes',
    AmericaMaceio = 'America/Maceio',
    AmericaManagua = 'America/Managua',
    AmericaManaus = 'America/Manaus',
    AmericaMarigot = 'America/Marigot',
    AmericaMartinique = 'America/Martinique',
    AmericaMatamoros = 'America/Matamoros',
    AmericaMazatlan = 'America/Mazatlan',
    AmericaMenominee = 'America/Menominee',
    AmericaMerida = 'America/Merida',
    AmericaMetlakatla = 'America/Metlakatla',
    AmericaMexicoCity = 'America/Mexico_City',
    AmericaMiquelon = 'America/Miquelon',
    AmericaMoncton = 'America/Moncton',
    AmericaMonterrey = 'America/Monterrey',
    AmericaMontevideo = 'America/Montevideo',
    AmericaMontserrat = 'America/Montserrat',
    AmericaNassau = 'America/Nassau',
    AmericaNewYork = 'America/New_York',
    AmericaNome = 'America/Nome',
    AmericaNoronha = 'America/Noronha',
    AmericaNorthDakotaBeulah = 'America/North_Dakota/Beulah',
    AmericaNorthDakotaCenter = 'America/North_Dakota/Center',
    AmericaNorthDakotaNewSalem = 'America/North_Dakota/New_Salem',
    AmericaNuuk = 'America/Nuuk',
    AmericaOjinaga = 'America/Ojinaga',
    AmericaPanama = 'America/Panama',
    AmericaParamaribo = 'America/Paramaribo',
    AmericaPhoenix = 'America/Phoenix',
    AmericaPortAuPrince = 'America/Port-au-Prince',
    AmericaPortOfSpain = 'America/Port_of_Spain',
    AmericaPortoVelho = 'America/Porto_Velho',
    AmericaPuertoRico = 'America/Puerto_Rico',
    AmericaPuntaArenas = 'America/Punta_Arenas',
    AmericaRankinInlet = 'America/Rankin_Inlet',
    AmericaRecife = 'America/Recife',
    AmericaRegina = 'America/Regina',
    AmericaResolute = 'America/Resolute',
    AmericaRioBranco = 'America/Rio_Branco',
    AmericaSantarem = 'America/Santarem',
    AmericaSantiago = 'America/Santiago',
    AmericaSantoDomingo = 'America/Santo_Domingo',
    AmericaSaoPaulo = 'America/Sao_Paulo',
    AmericaScoresbysund = 'America/Scoresbysund',
    AmericaSitka = 'America/Sitka',
    AmericaStBarthelemy = 'America/St_Barthelemy',
    AmericaStJohns = 'America/St_Johns',
    AmericaStKitts = 'America/St_Kitts',
    AmericaStLucia = 'America/St_Lucia',
    AmericaStThomas = 'America/St_Thomas',
    AmericaStVincent = 'America/St_Vincent',
    AmericaSwiftCurrent = 'America/Swift_Current',
    AmericaTegucigalpa = 'America/Tegucigalpa',
    AmericaThule = 'America/Thule',
    AmericaTijuana = 'America/Tijuana',
    AmericaToronto = 'America/Toronto',
    AmericaTortola = 'America/Tortola',
    AmericaVancouver = 'America/Vancouver',
    AmericaWhitehorse = 'America/Whitehorse',
    AmericaWinnipeg = 'America/Winnipeg',
    AmericaYakutat = 'America/Yakutat',
    AntarcticaCasey = 'Antarctica/Casey',
    AntarcticaDavis = 'Antarctica/Davis',
    AntarcticaDumontDUrville = 'Antarctica/DumontDUrville',
    AntarcticaMacquarie = 'Antarctica/Macquarie',
    AntarcticaMawson = 'Antarctica/Mawson',
    AntarcticaMcMurdo = 'Antarctica/McMurdo',
    AntarcticaPalmer = 'Antarctica/Palmer',
    AntarcticaRothera = 'Antarctica/Rothera',
    AntarcticaSyowa = 'Antarctica/Syowa',
    AntarcticaTroll = 'Antarctica/Troll',
    AntarcticaVostok = 'Antarctica/Vostok',
    ArcticLongyearbyen = 'Arctic/Longyearbyen',
    AsiaAden = 'Asia/Aden',
    AsiaAlmaty = 'Asia/Almaty',
    AsiaAmman = 'Asia/Amman',
    AsiaAnadyr = 'Asia/Anadyr',
    AsiaAqtau = 'Asia/Aqtau',
    AsiaAqtobe = 'Asia/Aqtobe',
    AsiaAshgabat = 'Asia/Ashgabat',
    AsiaAtyrau = 'Asia/Atyrau',
    AsiaBaghdad = 'Asia/Baghdad',
    AsiaBahrain = 'Asia/Bahrain',
    AsiaBaku = 'Asia/Baku',
    AsiaBangkok = 'Asia/Bangkok',
    AsiaBarnaul = 'Asia/Barnaul',
    AsiaBeirut = 'Asia/Beirut',
    AsiaBishkek = 'Asia/Bishkek',
    AsiaBrunei = 'Asia/Brunei',
    AsiaChita = 'Asia/Chita',
    AsiaChoibalsan = 'Asia/Choibalsan',
    AsiaColombo = 'Asia/Colombo',
    AsiaDamascus = 'Asia/Damascus',
    AsiaDhaka = 'Asia/Dhaka',
    AsiaDili = 'Asia/Dili',
    AsiaDubai = 'Asia/Dubai',
    AsiaDushanbe = 'Asia/Dushanbe',
    AsiaFamagusta = 'Asia/Famagusta',
    AsiaGaza = 'Asia/Gaza',
    AsiaHebron = 'Asia/Hebron',
    AsiaHoChiMinh = 'Asia/Ho_Chi_Minh',
    AsiaHongKong = 'Asia/Hong_Kong',
    AsiaHovd = 'Asia/Hovd',
    AsiaIrkutsk = 'Asia/Irkutsk',
    AsiaJakarta = 'Asia/Jakarta',
    AsiaJayapura = 'Asia/Jayapura',
    AsiaJerusalem = 'Asia/Jerusalem',
    AsiaKabul = 'Asia/Kabul',
    AsiaKamchatka = 'Asia/Kamchatka',
    AsiaKarachi = 'Asia/Karachi',
    AsiaKathmandu = 'Asia/Kathmandu',
    AsiaKhandyga = 'Asia/Khandyga',
    AsiaKolkata = 'Asia/Kolkata',
    AsiaKrasnoyarsk = 'Asia/Krasnoyarsk',
    AsiaKualaLumpur = 'Asia/Kuala_Lumpur',
    AsiaKuching = 'Asia/Kuching',
    AsiaKuwait = 'Asia/Kuwait',
    AsiaMacau = 'Asia/Macau',
    AsiaMagadan = 'Asia/Magadan',
    AsiaMakassar = 'Asia/Makassar',
    AsiaManila = 'Asia/Manila',
    AsiaMuscat = 'Asia/Muscat',
    AsiaNicosia = 'Asia/Nicosia',
    AsiaNovokuznetsk = 'Asia/Novokuznetsk',
    AsiaNovosibirsk = 'Asia/Novosibirsk',
    AsiaOmsk = 'Asia/Omsk',
    AsiaOral = 'Asia/Oral',
    AsiaPhnomPenh = 'Asia/Phnom_Penh',
    AsiaPontianak = 'Asia/Pontianak',
    AsiaPyongyang = 'Asia/Pyongyang',
    AsiaQatar = 'Asia/Qatar',
    AsiaQostanay = 'Asia/Qostanay',
    AsiaQyzylorda = 'Asia/Qyzylorda',
    AsiaRiyadh = 'Asia/Riyadh',
    AsiaSakhalin = 'Asia/Sakhalin',
    AsiaSamarkand = 'Asia/Samarkand',
    AsiaSeoul = 'Asia/Seoul',
    AsiaShanghai = 'Asia/Shanghai',
    AsiaSingapore = 'Asia/Singapore',
    AsiaSrednekolymsk = 'Asia/Srednekolymsk',
    AsiaTaipei = 'Asia/Taipei',
    AsiaTashkent = 'Asia/Tashkent',
    AsiaTbilisi = 'Asia/Tbilisi',
    AsiaTehran = 'Asia/Tehran',
    AsiaThimphu = 'Asia/Thimphu',
    AsiaTokyo = 'Asia/Tokyo',
    AsiaTomsk = 'Asia/Tomsk',
    AsiaUlaanbaatar = 'Asia/Ulaanbaatar',
    AsiaUrumqi = 'Asia/Urumqi',
    AsiaUstNera = 'Asia/Ust-Nera',
    AsiaVientiane = 'Asia/Vientiane',
    AsiaVladivostok = 'Asia/Vladivostok',
    AsiaYakutsk = 'Asia/Yakutsk',
    AsiaYangon = 'Asia/Yangon',
    AsiaYekaterinburg = 'Asia/Yekaterinburg',
    AsiaYerevan = 'Asia/Yerevan',
    AtlanticAzores = 'Atlantic/Azores',
    AtlanticBermuda = 'Atlantic/Bermuda',
    AtlanticCanary = 'Atlantic/Canary',
    AtlanticCapeVerde = 'Atlantic/Cape_Verde',
    AtlanticFaroe = 'Atlantic/Faroe',
    AtlanticMadeira = 'Atlantic/Madeira',
    AtlanticReykjavik = 'Atlantic/Reykjavik',
    AtlanticSouthGeorgia = 'Atlantic/South_Georgia',
    AtlanticStHelena = 'Atlantic/St_Helena',
    AtlanticStanley = 'Atlantic/Stanley',
    AustraliaAdelaide = 'Australia/Adelaide',
    AustraliaBrisbane = 'Australia/Brisbane',
    AustraliaBrokenHill = 'Australia/Broken_Hill',
    AustraliaDarwin = 'Australia/Darwin',
    AustraliaEucla = 'Australia/Eucla',
    AustraliaHobart = 'Australia/Hobart',
    AustraliaLindeman = 'Australia/Lindeman',
    AustraliaLordHowe = 'Australia/Lord_Howe',
    AustraliaMelbourne = 'Australia/Melbourne',
    AustraliaPerth = 'Australia/Perth',
    AustraliaSydney = 'Australia/Sydney',
    CanadaAtlantic = 'Canada/Atlantic',
    CanadaCentral = 'Canada/Central',
    CanadaEastern = 'Canada/Eastern',
    CanadaMountain = 'Canada/Mountain',
    CanadaNewfoundland = 'Canada/Newfoundland',
    CanadaPacific = 'Canada/Pacific',
    EuropeAmsterdam = 'Europe/Amsterdam',
    EuropeAndorra = 'Europe/Andorra',
    EuropeAstrakhan = 'Europe/Astrakhan',
    EuropeAthens = 'Europe/Athens',
    EuropeBelgrade = 'Europe/Belgrade',
    EuropeBerlin = 'Europe/Berlin',
    EuropeBratislava = 'Europe/Bratislava',
    EuropeBrussels = 'Europe/Brussels',
    EuropeBucharest = 'Europe/Bucharest',
    EuropeBudapest = 'Europe/Budapest',
    EuropeBusingen = 'Europe/Busingen',
    EuropeChisinau = 'Europe/Chisinau',
    EuropeCopenhagen = 'Europe/Copenhagen',
    EuropeDublin = 'Europe/Dublin',
    EuropeGibraltar = 'Europe/Gibraltar',
    EuropeGuernsey = 'Europe/Guernsey',
    EuropeHelsinki = 'Europe/Helsinki',
    EuropeIsleOfMan = 'Europe/Isle_of_Man',
    EuropeIstanbul = 'Europe/Istanbul',
    EuropeJersey = 'Europe/Jersey',
    EuropeKaliningrad = 'Europe/Kaliningrad',
    EuropeKirov = 'Europe/Kirov',
    EuropeKyiv = 'Europe/Kyiv',
    EuropeLisbon = 'Europe/Lisbon',
    EuropeLjubljana = 'Europe/Ljubljana',
    EuropeLondon = 'Europe/London',
    EuropeLuxembourg = 'Europe/Luxembourg',
    EuropeMadrid = 'Europe/Madrid',
    EuropeMalta = 'Europe/Malta',
    EuropeMariehamn = 'Europe/Mariehamn',
    EuropeMinsk = 'Europe/Minsk',
    EuropeMonaco = 'Europe/Monaco',
    EuropeMoscow = 'Europe/Moscow',
    EuropeOslo = 'Europe/Oslo',
    EuropeParis = 'Europe/Paris',
    EuropePodgorica = 'Europe/Podgorica',
    EuropePrague = 'Europe/Prague',
    EuropeRiga = 'Europe/Riga',
    EuropeRome = 'Europe/Rome',
    EuropeSamara = 'Europe/Samara',
    EuropeSanMarino = 'Europe/San_Marino',
    EuropeSarajevo = 'Europe/Sarajevo',
    EuropeSaratov = 'Europe/Saratov',
    EuropeSimferopol = 'Europe/Simferopol',
    EuropeSkopje = 'Europe/Skopje',
    EuropeSofia = 'Europe/Sofia',
    EuropeStockholm = 'Europe/Stockholm',
    EuropeTallinn = 'Europe/Tallinn',
    EuropeTirane = 'Europe/Tirane',
    EuropeUlyanovsk = 'Europe/Ulyanovsk',
    EuropeVaduz = 'Europe/Vaduz',
    EuropeVatican = 'Europe/Vatican',
    EuropeVienna = 'Europe/Vienna',
    EuropeVilnius = 'Europe/Vilnius',
    EuropeVolgograd = 'Europe/Volgograd',
    EuropeWarsaw = 'Europe/Warsaw',
    EuropeZagreb = 'Europe/Zagreb',
    EuropeZurich = 'Europe/Zurich',
    Gmt = 'GMT',
    IndianAntananarivo = 'Indian/Antananarivo',
    IndianChagos = 'Indian/Chagos',
    IndianChristmas = 'Indian/Christmas',
    IndianCocos = 'Indian/Cocos',
    IndianComoro = 'Indian/Comoro',
    IndianKerguelen = 'Indian/Kerguelen',
    IndianMahe = 'Indian/Mahe',
    IndianMaldives = 'Indian/Maldives',
    IndianMauritius = 'Indian/Mauritius',
    IndianMayotte = 'Indian/Mayotte',
    IndianReunion = 'Indian/Reunion',
    PacificApia = 'Pacific/Apia',
    PacificAuckland = 'Pacific/Auckland',
    PacificBougainville = 'Pacific/Bougainville',
    PacificChatham = 'Pacific/Chatham',
    PacificChuuk = 'Pacific/Chuuk',
    PacificEaster = 'Pacific/Easter',
    PacificEfate = 'Pacific/Efate',
    PacificFakaofo = 'Pacific/Fakaofo',
    PacificFiji = 'Pacific/Fiji',
    PacificFunafuti = 'Pacific/Funafuti',
    PacificGalapagos = 'Pacific/Galapagos',
    PacificGambier = 'Pacific/Gambier',
    PacificGuadalcanal = 'Pacific/Guadalcanal',
    PacificGuam = 'Pacific/Guam',
    PacificHonolulu = 'Pacific/Honolulu',
    PacificKanton = 'Pacific/Kanton',
    PacificKiritimati = 'Pacific/Kiritimati',
    PacificKosrae = 'Pacific/Kosrae',
    PacificKwajalein = 'Pacific/Kwajalein',
    PacificMajuro = 'Pacific/Majuro',
    PacificMarquesas = 'Pacific/Marquesas',
    PacificMidway = 'Pacific/Midway',
    PacificNauru = 'Pacific/Nauru',
    PacificNiue = 'Pacific/Niue',
    PacificNorfolk = 'Pacific/Norfolk',
    PacificNoumea = 'Pacific/Noumea',
    PacificPagoPago = 'Pacific/Pago_Pago',
    PacificPalau = 'Pacific/Palau',
    PacificPitcairn = 'Pacific/Pitcairn',
    PacificPohnpei = 'Pacific/Pohnpei',
    PacificPortMoresby = 'Pacific/Port_Moresby',
    PacificRarotonga = 'Pacific/Rarotonga',
    PacificSaipan = 'Pacific/Saipan',
    PacificTahiti = 'Pacific/Tahiti',
    PacificTarawa = 'Pacific/Tarawa',
    PacificTongatapu = 'Pacific/Tongatapu',
    PacificWake = 'Pacific/Wake',
    PacificWallis = 'Pacific/Wallis',
    UsAlaska = 'US/Alaska',
    UsArizona = 'US/Arizona',
    UsCentral = 'US/Central',
    UsEastern = 'US/Eastern',
    UsHawaii = 'US/Hawaii',
    UsMountain = 'US/Mountain',
    UsPacific = 'US/Pacific',
    Utc = 'UTC'
}
export enum TopologyType {
    Hole = 'hole',
    Slot = 'slot',
    Pocket = 'pocket',
    Notch = 'notch',
    Reduction = 'reduction'
}
export enum WidgetSignInMode {
    Upload = 'upload',
    AddToCart = 'add_to_cart'
}
export enum ZipGroupingMethod {
    GroupByObjectModel = 'group_by_object_model',
    GroupByPurchase = 'group_by_purchase'
}
